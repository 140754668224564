import manager from "./assets/images/manager-card-front.png";
import {Button, List, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import MembershipCard from "./MembershipCard";
import {WHITELIST_LINK} from "./components/constants";
import BulletPoint from "./components/BulletPoint";

const MembershipManager = () => {
    return <React.Fragment>
        <MembershipCard
            name={"Blood Hammer"}
            type={"manager"}
            img={manager}
            color={"#ed1c24"}
            total={1337}
            cost={0.75}/>
        <Typography variant={"body1"}>
            All of the benefits of a Trainer Membership and...
        </Typography>
        <List>
            <BulletPoint>
                One <a href={"https://companystore.evilplaninc.com/product/snazzy-hat"}>
                Snazzy Hat
            </a> & one <a href={"https://companystore.evilplaninc.com/product/snazzy-mug"}>
                Snazzy Mug
            </a>.
            </BulletPoint>
            <BulletPoint>
               4x Daojo governance rights for each Manager Membership NFT you hold (4 votes)
            </BulletPoint>
            <BulletPoint>
                Exclusive Daojo Manager items, expansions, and mods for their Daojo's AI Champion
            </BulletPoint>
            <BulletPoint>
                Premium access to official Daojo league championship events
            </BulletPoint>
            <BulletPoint>
                Access to IRL and Discord networking events with other Manager Members who opt-in
            </BulletPoint>
            <BulletPoint>
                Exclusive access to high-stakes Managers-only fantasy contests and fights
            </BulletPoint>
        </List>
        <Box style={{
            flexGrow: 1,
        }}/>
        <Button variant={"outlined"}
                href={WHITELIST_LINK}
                style={{
                    padding: 10,
                    borderColor: "#ed1c24"
                }}>
            <Typography variant={"h6"} style={{
                fontWeight: 700,
                color: "#ed1c24"
            }}>
                Get Blacklisted Now
            </Typography>
        </Button>
        <Typography variant={"caption"} marginTop={1} color={"rgba(255,255,255,0.50)"}>
            Requires an minimum verifiable balance of <span style={{
            color: "rgba(255,255,255,0.75)"
        }}>0.25 ETH</span>
        </Typography>
    </React.Fragment>
}

export default MembershipManager;

// Buy Now on OpenSea
