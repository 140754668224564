import React, {PropsWithChildren} from "react";
import {ListItem, ListItemIcon, ListItemText, useMediaQuery, useTheme} from "@mui/material";
//import {ReactComponent as StarIcon} from "./assets/images/pentagramagon.svg";
import CheckIcon from '@mui/icons-material/Check';

const BulletPoint = ({children}: PropsWithChildren<any>) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const pad = Boolean(matches) ? '0px' : '8px 16px 8px 16px';
    return <ListItem style={{
        padding: pad,
    }}>
        <ListItemIcon sx={{fontSize: 24}} style={{
            minWidth: 0,
            marginRight: 16,
            color: "#50e558"
        }}>
            <CheckIcon/>
        </ListItemIcon>
        <ListItemText primary={children}/>
    </ListItem>
}

export default BulletPoint;