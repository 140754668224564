import {Grid, Typography} from "@mui/material";
import React from "react";
import './ContactPanel.css';
import Footer from "./Footer";
import Box from "@mui/material/Box";
import DiscordButton from "./components/DiscordButton";

const ContactPanel = () => {
    return <Grid item xs={12} className={"contact"} style={{
        display: "flex",
        flexDirection: "column",
    }}>
        <Box>
            <Typography
                fontSize={{xs: "10vmin", sm: "6vmin"}}
                paddingBottom={{xs: 1, sm: 5}} paddingTop={{xs: 5, sm: 30}} style={{
                textTransform: "uppercase",
                fontWeight: 100,
                color: "#e71414",
            }}>
                <span style={{color: "#e71414"}}>Welcome to the<br/>
                    Pentagramagon</span>
            </Typography>
            <Typography paddingBottom={5} paddingLeft={2} paddingRight={2} style={{
                margin: 'auto',
                maxWidth: 600,
            }}>
                The SFC community is filled with gamers, professionals athletes, sports fans, streamers, and web3
                enthusiasts — all focused on our shared goal — creating the next generation of sports
                entertainment! Our Discord will be your portal to the community and your Daojo. Connect with other
                Members and fans to collaborate, strategize, or just chill with friends in the SFC community.
            </Typography>
            <DiscordButton backgroundColor={"#e52026"}/>
            <Typography paddingTop={1} color={"rgba(255,255,255,0.50)"}>
                Dank memes are always welcome.
            </Typography>
        </Box>
        <Box flexGrow={1}/>
        <Footer/>
    </Grid>
}

export default ContactPanel
