import {Typography} from "@mui/material";
import Box from "@mui/material/Box";

const Footer = () => {
    return <Box display={'flex'} padding={{xs: 1, sm: 3}} style={{
        textTransform: "uppercase",
        color: "rgba(79,79,79,0.5)",
        width: "100%",
        flexDirection: "column"
    }}>
        <Typography variant={"caption"} lineHeight={{xs: 1, sm: 1.66}} gutterBottom={true}>
            Secret Fight Club™, Daojo™, and Pentagramagon™ are trademarks of EvilPlan Inc.
        </Typography>
        <Typography variant={"caption"}>
            ©2022 EvilPlan, Inc. All Rights Reserved.
        </Typography>
    </Box>
}

export default Footer;
