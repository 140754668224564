import {Box, Button, Grid, Typography} from "@mui/material";
import evilplan from "./assets/images/evilplan-logo.svg";
import {WHITELIST_LINK} from "./components/constants";
import cardhero from "./assets/images/hero-card-back.png";
import React, {PropsWithChildren} from "react";
import {Bold} from "./components/Bold";

interface BulletPointProps extends PropsWithChildren<any>{
    title: string;
}

export const Bullet = ({title, children}:BulletPointProps) => {
    return (

            <Typography variant={"h6"} gutterBottom={true} paragraph={true} fontWeight={"bold"}>
           {title}: <Typography variant={"h6"} color={"#e8e8e8"} fontWeight={"normal"} component={"span"}>
                {children}
            </Typography>
            </Typography>


    )
}

export const IntroPanel = () => {
    return <Grid container spacing={0} maxWidth={1024}>
        <Grid container item xs={12} paddingBottom={{xs: 5, sm: 10}} paddingTop={{xs: 5, sm: 10}}>
            <Grid item xs={12} md={7} style={{
                padding: "4vmin",
                display: "flex",
                flexDirection: "column",
                alignContent: "left",
                textAlign: "left",
                color: "#cdd1de",
            }}>
                <Typography variant={"h3"} fontWeight={"bold"}>
                    <img src={evilplan} style={{
                        verticalAlign: "middle",
                        height: 'auto',
                        width: "1em",
                        marginRight: '0.4em',
                        paddingBottom: "0.2em",
                    }} alt={"EvilPlan Inc."}/>
                    Secret Fight Club™
                </Typography>
                <Typography variant={"h5"} gutterBottom={true} paragraph={true}>
                    Your Daojo™ team configures an AI fighter to compete in an online tournament for cash and prizes.
                </Typography>
                <Box flexGrow={1}/>
                <ul style={{listStyleType:"square", paddingLeft: 20}}>

                    <Bullet title={"Join a Daojo"}>
                        Team members earn rewards from fights
                    </Bullet>

                    <Bullet title={"Build a Champion"}>
                        Team members configure their fighter
                    </Bullet>

                    <Bullet title={"Watch Live"}>
                        Spectate any streaming fights for free
                    </Bullet>

                    <Bullet title={"Fantasy Sports"}>
                        Enter contests to win cash & prizes
                    </Bullet>

                    <Bullet title={"Design Items"}>
                        Collab on weapons & upgrades (Discord)
                    </Bullet>

                </ul>
                <Box flexGrow={1}/>
                <Button variant={"outlined"}
                        fullWidth={true}
                        href={"#mint"}
                        style={{
                            padding: 20,
                            borderColor: "#ed1c24"
                        }}>
                    <Typography variant={"h4"} style={{
                        fontWeight: 700,
                        color: "#ed1c24"
                    }}>
                        Become a Member
                    </Typography>
                </Button>
            </Grid>
            <Grid item xs={0} md={5} display={{xs: "none", md: "block"}}>
                <img src={cardhero} style={{
                    objectFit: "contain",
                    width: '100%',
                    height: '100%',
                }} alt={"SFC Membership"}/>
            </Grid>
        </Grid>
    </Grid>
}

/*

  <Grid item xs={12} md={6}>
                <Typography variant={"h3"} gutterBottom={true} paragraph={true} fontWeight={100}  color={"#80828d"} >
                    Ten Daojos
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant={"h3"} gutterBottom={true} paragraph={true}  fontWeight={100}  color={"#80828d"} >
                    One Winner
                </Typography>
            </Grid>

     In our game, you join one of 10 teams, called a <Bold>Daojo™</Bold>. Daojo
                    members <Bold>design</Bold> an <Bold>autonomous</Bold> AI robot fighter — a <Bold>Champion</Bold> —
                    to battle it out <Bold>on stream</Bold> for <Bold>cash & prizes</Bold>.

  <Typography variant={"h5"} gutterBottom={true} paragraph={true}>
                    Your Daojo is responsible for building and training <Bold>one Champion</Bold> —
                    an <Bold>autonomous</Bold> Artificially Intelligent (AI) Robot Fighter.
                </Typography>
                <Typography variant={"h5"} gutterBottom={true} paragraph={true}>
                    As a <Bold>Member</Bold> of a Daojo, you <Bold>strategize and vote</Bold> electronically on the
                    loadout, design, and capabilities of your team’s Champion.
                </Typography>
                <Typography variant={"h5"} gutterBottom={true} paragraph={true}>
                    <Bold>Watch</Bold> your Daojo’s Champion compete in <Bold>live online streaming tournaments</Bold> for
                    glory and a shot at the league title.
                </Typography>
                <Typography variant={"h5"} gutterBottom={true} paragraph={true}>
                    Most importantly, there will be <Bold>cash</Bold>, <Bold>prizes</Bold>, and of course, <Bold>pizza</Bold>.
                </Typography>
 */

