import React from "react";
import discord from '../assets/images/locked.svg';
import {WHITELIST_LINK} from "./constants";

const JoinButton = ({url}: {url?: string }) => (
    <a
        href={url ? url : WHITELIST_LINK}
        style={{
            fontSize: 20,
            color: "white",
            fontWeight: "bold",
            borderRadius: 8,
            display: "inline-flex",
            alignItems: "center",
            padding: "10px 15px",
            backgroundColor: "rgba(0,0,0,0.65)",
            textDecoration: "none",
            minWidth: 280,
            borderStyle: "solid",
            borderColor: "#fff",
            borderWidth: 1,
        }}
    >
        <img style={{
            width: 35,
            height: "auto",
            marginLeft: -2,
            marginRight: 10,
            marginTop: 2,
            color: "white",
        }} src={discord} alt={"Become a Member"}/>
        <span style={{
            textTransform: "uppercase"
        }}>{ "Become a Member"}</span>
    </a>
);

export default JoinButton;