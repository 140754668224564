import {Box, Button, Grid, IconButton, Typography} from "@mui/material";
import React, {PropsWithChildren, useRef, useState} from "react";
import timeline from "./assets/images/daojo-timeline.svg";
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @ts-ignore
import gameplay from "./assets/video/gameplay.mp4";
import './GameplayPanel.css';
import TextLoop from "react-text-loop";
import ReactPlayer from "react-player";
import {DEMO_VIDEO_URL} from "./components/constants";
import WatchButton from "./components/WatchButton";
import clsx from "clsx";
import play from "./assets/images/play-circle.svg";
import PlayCircle from '@mui/icons-material/PlayCircle';

interface TextBoxProps extends PropsWithChildren<any> {
    title: any;
}

const TextBox = ({title, children}: TextBoxProps) => {
    return (
        <Box width={340}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}>
        <Typography
            variant={"h1"}
            paragraph={true}
            fontWeight={900}
            textTransform={"uppercase"}
            style={{
                fontSize: "min(16vw, 160px)",
                display: "inline-block",
                whiteSpace: "pre-line",
            }}>
            {title}
        </Typography>
        <Typography
            variant={"h4"}
            fontWeight={100}
            color={"#93949f"}
            align={"center"}
            style={{
                display: "inline-block",
                whiteSpace: "pre-line",
            }}>
            {children}
        </Typography>
    </Box>
    );
}

interface PlayState {
    mute: boolean;
    controls: boolean;
}

const GameplayPanel = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const [playState, setPlayState] = useState<PlayState>({
        mute: true,
        controls: false,
    });
    const playerRef = useRef(null);
    return (
        <Box  className={clsx("gameplay-demo",  playState.mute && "gameplay-filter")}>
            <a id={"gameplay"}/>
            <ReactPlayer  ref={playerRef}
                className={clsx("gameplay-video")}
                url={DEMO_VIDEO_URL}
                playsinline={true}
                loop={true}
                controls={false}
                muted={playState.mute}
                playing={true}
                width="100%"
                height="100%"
                config={{
                    youtube: {
                        playerVars: { start: 8 }
                    },
                    file: {
                        attributes: {
                            style: {
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            },
                        },
                    },
                }}
            />
            <Box className={clsx("gameplay-controls", playState.mute && "gameplay-dimmer")}/>
            <Button className={clsx("gameplay-controls")}
                 onClick={()=>{
                     if ( playState.mute) {
                         // @ts-ignore
                         playerRef.current?.seekTo(3);
                         setPlayState({mute: false, controls: true});
                         return;
                     }
                     setPlayState({mute: true, controls: false});
                 }}>
                {playState.mute &&
                    <IconButton >
                        <PlayCircle style={{
                            width: 200,
                            height: "auto",
                            color: "rgba(255,255,255,0.5)",
                        }}/>
                    </IconButton>
                }
            </Button>
        </Box>
    );
}

export default GameplayPanel;

/*

 style={{
                visibility: playState.mute ? "visible" : "hidden"
            }}

        <img style={{
            width: 35,
            height: "auto",
            marginLeft: -2,
            marginRight: 10,
            marginTop: 2,
            color: "white",
        }} src={play} alt={"Watch The Video"}/>
        <span style={{
            textTransform: "uppercase"
        }}>{ "Watch The Video"}</span>


            <Box className={"gameplay-text"}>
                <TextLoop >
                    <TextBox title={"Build Champions"}>
                        Daojo members vote
                        <br/>
                        on design & strategy
                    </TextBox>

                    <TextBox title={"Earn Directly"}>
                        Join a Daojo to share
                        <br/>
                        in member revenue
                    </TextBox>

                    <TextBox title={<>Watch<br/>Live</>}>
                        Spectate any match
                        <br/>
                        online for free
                    </TextBox>

                    <TextBox title={"Fantasy Sports"}>
                        Enter contests for
                        <br/>
                        cash & prizes
                    </TextBox>

                    <TextBox title={"Design Items"}>
                        Collab on weapons &<br/>
                        equipment in Discord
                    </TextBox>
                </TextLoop>
            </Box>
            <a href={demoURL} className={"gameplay-link"}/>
 */
