import {Button, List, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import MembershipCard from "./MembershipCard";
import trainer from "./assets/images/trainer-card-front.png";
import {WHITELIST_LINK} from "./components/constants";
import BulletPoint from "./components/BulletPoint";

function MembershipTrainer() {
    return <React.Fragment>
        <MembershipCard
            name={"Blood Hammer"}
            type={"trainer"}
            img={trainer}
            color={"#60acff"}
            total={3142}
            cost={0.25}
        />
        <Typography variant={"body1"}>
            Your Trainer Membership includes...
        </Typography>
        <List>
            <BulletPoint>
                One <a href={"https://companystore.evilplaninc.com/product/counterfeit-hat"}>
                Counterfeit Hat
            </a>.
            </BulletPoint>
            <BulletPoint>
                1x governance rights in the Daojo for each Trainer Membership NFT you hold (1 vote)
            </BulletPoint>
            <BulletPoint>
                Proportional revenue share of the Daojo equal to your total number of votes (pro-rata)
            </BulletPoint>
            <BulletPoint>
                The right to participate in all aspects of the management and training of your Daojo’s Champion — including cosmetics, buffs, mods, and genetics
            </BulletPoint>
            <BulletPoint>
                Exclusive access to SFC tournament prize pools and potential 3rd-party sponsorship deals
            </BulletPoint>
            <BulletPoint>
                Entry to members-only Discord channels, including special guest appearances by fellow sports, gaming, and celebrity members
            </BulletPoint>
            <BulletPoint>
                Discounts on exclusive in-game NFT items & upgrades for your AI Champion
            </BulletPoint>
            <BulletPoint>
                Free and discounted members-only Daojo & SFC merch
            </BulletPoint>
        </List>
        <Box style={{
            flexGrow: 1,
        }}/>
        <Button variant={"outlined"}
                href={WHITELIST_LINK}
                style={{
                    padding: 10,
                    borderColor: "#60acff"
                }}>
            <Typography variant={"h6"} style={{
                fontWeight: 700,
                color: "#60acff"
            }}>
                Get Blacklisted Now
            </Typography>
        </Button>
        <Typography variant={"caption"} marginTop={1} color={"rgba(255,255,255,0.50)"}>
            Requires an minimum verifiable balance of <span style={{
            color: "rgba(255,255,255,0.75)"
        }}>0.25 ETH</span>
        </Typography>
    </React.Fragment>
}

export default MembershipTrainer;

// Buy Now on OpenSea
