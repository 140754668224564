import React from "react";
import play from '../assets/images/play-circle.svg';
import {DEMO_VIDEO_URL} from "./constants";

const WatchButton = ({url}: {url?: string }) => (
    <a
        href={url ? url : DEMO_VIDEO_URL}
        style={{
            fontSize: 20,
            color: "white",
            fontWeight: "bold",
            borderRadius: 8,
            display: "inline-flex",
            alignItems: "center",
            padding: "10px 15px",
            backgroundColor: "rgba(0,0,0,0.65)",
            textDecoration: "none",
            minWidth: 280,
            borderStyle: "solid",
            borderColor: "#fff",
            borderWidth: 1,
        }}
    >
        <img style={{
            width: 35,
            height: "auto",
            marginLeft: -2,
            marginRight: 10,
            marginTop: 2,
            color: "#fff",
        }} src={play} alt={"Watch The Video"}/>
        <span style={{
            textTransform: "uppercase"
        }}>{ "Watch The Video"}</span>
    </a>
);

export default WatchButton;