import React from "react";
import {Container, Grid, Typography} from "@mui/material";
// @ts-ignore
import Obfuscate from "react-obfuscate";
import evilplan from "./assets/images/evilplan-lockup.svg";
import Box from "@mui/material/Box";
import {HashLink} from 'react-router-hash-link';
import ScrollToTop from "./components/ScrollToTop";

const LegalPage = () => {
    return <Grid item xs={12} textAlign={"left"} paddingBottom={10} paddingTop={2}>
        <ScrollToTop/>
        <Container>
            <Box display={"flex"}
                 paddingBottom={6}
                 marginTop={2}>
                <img src={evilplan} style={{
                    height: '3em',
                    width: "auto",
                    marginRight: '0.4em',
                }} alt={"EvilPlan Inc."}/>
                <Box flexGrow={1}/>
                <Typography variant={"caption"}>
                    ⬅️ <HashLink to={"/#mint"}>Home</HashLink>
                </Typography>
            </Box>

            <Typography variant={"h2"} fontWeight={700} paragraph={true}>
                Terms of Sale
            </Typography>

            <Typography variant={"subtitle1"} paragraph={true}>
                Last Modified: April 1, 2022
            </Typography>

            <Typography variant={"body1"} paragraph={true}>
                EvilPlan Inc. ("We" or "EvilPlan") plans to establish and operate a socially managed video game, Secret
                Fight Club ("SFC" or "The Game"). EvilPlan plans to utilize cryptographically secure blockchain
                organization management tools to automate and improve transparency around certain aspects of
                organizational governance and management of the Non-Player Characters ("NPCs") in The Game via a Daojo,
                commonly known as a "decentralized autonomous organization" or a "DAO."
            </Typography>
            <Typography variant={"body1"} paragraph={true}>
                We are offering for sale two types of digital collectibles (each a "Membership NFT") on or about April
                20th, 2022: a "trainer" membership NFT ("Trainer Membership") and "manager" membership NFT ("Manager
                Membership").
            </Typography>
            <Typography variant={"body1"} paragraph={true}>
                By purchasing any Membership NFT, you confirm that you understand and agree to these terms of sale,
                together with any documents that may be expressly referred to and are incorporated by reference (“Terms
                of Sale”). These Terms of Sale constitute a legal agreement between you and EvilPlan and govern your
                access to and use of the Membership NFTs, including any content, functionality, and services offered on
                or through Membership NFTs.
            </Typography>
            <Typography variant={"body1"} paragraph={true} fontWeight={"bolder"}>
                IF ANY PROVISION OF THESE TERMS OF SALE ARE UNACCEPTABLE TO YOU, DO NOT PURCHASE ANY MEMBERSHIP NFT.
            </Typography>
            <Box display={"flex"}
                 justifyContent={"center"}
                 marginBottom={"2rem"}
                 marginTop={"2rem"}
                 width={"100%"}>
                <div style={{width: "50%", height: 1, backgroundColor: "rgba(255,255,255,0.65)"}}/>
            </Box>
            <Typography variant={"body1"} paragraph={true}>
                The purchase of each NFT is a transaction in goods and not a promise to provide, or a guarantee of
                receipt of, future services from EvilPlan, although EvilPlan will make reasonable efforts to ensure that
                a DAO comes into existence which allows you to unlock expanded functionality associated with the
                Membership NFTs. Right to membership in the Daojo will be subject to Daojo rules, the terms of which
                will be determined if and when the Daojo comes into existence in coordination with the DAO.
            </Typography>
            <Typography variant={"body1"} paragraph={true}>
                You understand and agree that the sale of Membership NFTs grants you no rights and carries with it no
                guarantee of future performance of any kind by EvilPlan Inc. or The Game. You are not entitled, as a
                holder of any Membership NFT, to vote or receive dividends or profits or be deemed the holder of shares
                of EvilPlan Inc. or any other person by virtue of your ownership of a Membership NFT, nor will anything
                contained herein be construed to construe on you any of the rights of a shareholder, partner, joint
                venturer, etc. or any right to vote for the election of directors or upon any matter submitted to
                shareholders at any meeting thereof, or to give or withhold consent to any corporate action or to
                receive notice of meetings, or to receive subscription rights to purchase such shares of EvilPlan Inc.
                EvilPlan Inc. may in future offer holders of Membership NFTs the opportunity to participate in massively
                open online governance via DAO. You agree that the functionality of any DAO established by EvilPlan will
                be determined by EvilPlan in its sole and absolute discretion.
            </Typography>
            <Typography variant={"body1"} paragraph={true}>
                You understand that the development of EvilPlan’s business is a significantly risky venture and you
                acknowledge and assume the risk that EvilPlan or The Game may cease operations at any time and for any
                reason and may not be able to complete or continue, substantially or in part, any other aspect of its
                operations or roadmap and furthermore that a significant proportion of the funds generated by the
                Membership NFT drop are expected to be retained by EvilPlan Inc. for any lawful business purpose. You
                understand and expressly accept that the Membership NFTs will be created and delivered to you at the
                sole risk of yourself on an “AS IS”
                basis. <Typography variant={"body1"} fontWeight={"bolder"} component={"span"}>
                WE MAKE NO WARRANTY WHATSOEVER WITH RESPECT TO THE MEMBERSHIP NFTS, THE DAO OR THE FUNCTIONALITY OF THE
                PROTOCOL, INCLUDING ANY (i) WARRANTY OF MERCHANTABILITY; (ii) WARRANTY OF FITNESS FOR A PARTICULAR
                PURPOSE; (iii) WARRANTY OF TITLE; OR (iv) WARRANTY AGAINST INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS
                OF A THIRD-PARTY; WHETHER ARISING BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE OR
                OTHERWISE. EXCEPT AS EXPRESSLY SET FORTH HEREIN, YOU ACKNOWLEDGE THAT YOU HAVE NOT RELIED UPON ANY
                REPRESENTATION OR WARRANTY MADE BY US, OR ANY OTHER PERSON ON OUR BEHALF.
            </Typography>
            </Typography>
            <Typography variant={"body1"} paragraph={true}>
                Purchaser has not relied on any representations or warranties made by EvilPlan Inc. or any other person
                outside of the context of these Terms of Sale, including but not limited to conversations of any kind,
                including but not limited to whether such statements are made through oral or electronic communication,
                or any White Paper, social media post or website.
            </Typography>
            <Typography variant={"body1"} paragraph={true}>
                The sale of the Membership NFT is governed by, and these Terms of Sale will be construed in accordance
                with, Washington State law, without regard to conflict of laws principles. All disputes with EvilPlan by
                you or your successors or assigns directly or indirectly arising under or in relation to these Terms of
                Sale or the NFTS shall be brought and resolved solely and exclusively in final and binding arbitration
                in the State of Washington, in the City of Seattle, and under the Rules of Arbitration of the American
                Arbitration Association.
            </Typography>
            <Typography variant={"body1"} paragraph={true} fontWeight={"bolder"}>
                YOU ARE ACQUIRING THE MEMBERSHIP NFTS FOR YOUR OWN ACCOUNT FOR USE PURPOSES ONLY AND NOT WITH A VIEW
                TOWARDS RESALE OR “DISTRIBUTION” (WITHIN THE MEANING OF THE SECURITIES ACT) OF ANY PART OF THE
                MEMBERSHIP NFTS.
            </Typography>
            <Typography variant={"body1"} paragraph={true} fontWeight={"bolder"}>
                TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW (i) IN NO EVENT WILL WE (OR OUR AFFILIATES,
                SUBSIDIARIES, OFFICERS, EMPLOYEES, MANAGERS, REPRESENTATIVES, DIRECTORS OR CONTROL PERSONS (THE “RELATED
                PARTIES”)) BE LIABLE FOR LOSS OF PROFITS OR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR
                EXEMPLARY DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, WHERE RELATED TO LOSS OF REVENUE, INCOME
                OR PROFITS, LOSS OF USE OR DATA, OR DAMAGES FOR BUSINESS INTERRUPTION) ARISING OUT OF OR IN ANY WAY
                RELATED TO THE SALE OR USE OF THE MEMBERSHIP NFTS OR OTHERWISE RELATED TO THESE TERMS, REGARDLESS OF THE
                FORM OF ACTION, WHETHER BASED IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, SIMPLE NEGLIGENCE,
                WHETHER ACTIVE, PASSIVE OR IMPUTED), OR ANY OTHER LEGAL OR EQUITABLE THEORY (EVEN IF THE PARTY HAS BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE), AND
                (ii) IN NO EVENT WILL THE AGGREGATE LIABILITY OF EVILPLAN (AND OUR RELATED PARTIES (JOINTLY)), WHETHER
                IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), OR OTHER THEORY,
                ARISING OUT OF OR RELATING TO THESE TERMS OR THE USE OF OR INABILITY TO USE MEMBERSHIP NFTS, EXCEED THE
                U.S. DOLLAR EQUIVALENT OF THE PURCHASE PRICE PAID BY YOU UNDER THE TERMS OF SALE.
            </Typography>
            <Typography variant={"body1"} paragraph={true} fontWeight={"bolder"}>
                CLASS ACTION WAIVER: TO THE EXTENT PERMISSIBLE BY LAW, ALL CLAIMS MUST BE BROUGHT IN A PARTY’S
                INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, COLLECTIVE ACTION,
                OR REPRESENTATIVE PROCEEDING. THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON'S CLAIMS OR ENGAGE
                IN ANY CLASS ARBITRATION. YOU ACKNOWLEDGE THAT, BY AGREEING TO THESE TERMS, YOU AND EVILPLAN ARE EACH
                WAIVING THE RIGHT TO A TRIAL BY A JUDGE OR A JURY IN A PUBLIC FORUM AND THE RIGHT TO PARTICIPATE IN A
                CLASS ACTION.
            </Typography>
            <Typography variant={"body1"} paragraph={true}>
                Without prejudice to any other provision of these Terms of Sale, you acknowledge and agree that, except
                as otherwise required by any non-waivable provision of applicable law, EvilPlan Inc. shall not be liable
                in any manner whatsoever to you or any other person for losses or damages of any kind, whether arising
                in contract, tort, or otherwise, arising from the sale of Membership NFTs to any person.
            </Typography>
            <Typography variant={"body1"} paragraph={true}>
                Any amounts that you pay for the Membership NFTs are exclusive of all applicable taxes. You are
                responsible for determining what, if any, taxes apply to your purchase. It is your responsibility to
                withhold, collect, report and remit the correct taxes to the appropriate tax authorities. We are not
                responsible for withholding, collecting, reporting or remitting any sales, use, value added or similar
                tax arising from your purchase. Notwithstanding the preceding sentence, we reserve the right to collect
                and remit to applicable governmental authorities any amounts that we determine that it has a legal
                obligation to collect or remit in connection with the purchase and sale of Membership NFTs.
            </Typography>
            <Typography variant={"body1"} paragraph={true}>
                You acknowledge and agree that the sale to you of Membership NFTs is final, and that you shall have no
                right to cancel or terminate the purchase and sale transaction (or to receive any refund of all or any
                portion of the purchase price). Notwithstanding the foregoing, we reserve the right, in our sole
                discretion, to reject any purchase of Membership NFTs, in which case we will refund the purchase price
                to you.
            </Typography>
            <Typography variant={"body1"} paragraph={true}>
                The Membership NFTs are created as ERC-721 tokens on the Ethereum protocol. We reserve the right to
                migrate the ERC-721 based tokens (the “Initial Tokens”) to another protocol, and to generate replacement
                tokens on the new protocol (the “Replacement Tokens”) in the future, should we determine, in our sole
                discretion, that doing so is necessary or useful to the operation of SFC. We may no longer provide any
                support for the Initial Tokens or any other operational matters, except with respect to the migration
                process. By purchasing the membership NFTs, you acknowledge and agree that in order for you to continue
                to participate in the SFC or obtain utility from the Membership NFTs, you may need to convert the
                Initial Tokens to Replacement Tokens in the future.
            </Typography>
            <Typography variant={"body1"} paragraph={true}>
                If you have any questions please contact EvilPlan Inc. at <Obfuscate email="legal@evilplan.gg"/>
            </Typography>
        </Container>
    </Grid>
}

export default LegalPage;
