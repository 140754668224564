import React, {PropsWithoutRef} from "react";
import discord from '../assets/images/discord-logo.svg';
import {DISCORD_INVITE} from "./constants";

interface DiscordButtonProps extends PropsWithoutRef<any> {
    isSSO?: boolean;
    url?: string;
    backgroundColor?: string;
}

const DiscordButton = ({isSSO, url, backgroundColor}: DiscordButtonProps) => (
    <a
        href={url ? url :DISCORD_INVITE}
        style={{
            fontSize: 20,
            color: "white",
            fontWeight: "bold",
            borderRadius: 8,
            display: "inline-flex",
            alignItems: "center",
            padding: "10px 15px",
            backgroundColor: backgroundColor ? backgroundColor : "rgba(0,0,0,0.65)",
            textDecoration: "none",
            minWidth: 280,
            borderStyle: "solid",
            borderColor: "#fff",
            borderWidth: backgroundColor ? 0 : 1,
        }}
    >
        <img style={{
            width: 35,
            height: "auto",
            marginRight: 10,
            marginTop: 2,
            color: "white",
        }} src={discord} alt={"Join our Discord"}/>
        <span style={{
            textTransform: "uppercase"
        }}>{isSSO ? "Sign in with Discord" : "Join our Discord"}</span>
    </a>
);

export default DiscordButton;