import {Grid, Typography} from "@mui/material";
import React from "react";
import Box from '@mui/material/Box';
import {styled, useTheme} from '@mui/material/styles';


import MembershipManager from "./MembershipManager";
import MembershipTrainer from "./MembershipTrainer";
import useMediaQuery from "@mui/material/useMediaQuery";

const ItemContainer = styled(Grid)(({theme}) => ({
    ...theme.typography.body2,
    textAlign: 'left',
    display: "flex",
    flexDirection: "column",
}));

const Item = styled(Box)(({theme}) => ({
    border: '1px solid',
    borderColor: '#32353f',
    borderRadius: 8,
    height: '100%',
    display: "flex",
    flexDirection: "column",
}));

const MembershipPanel = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return <Grid item container spacing={4} padding={{xs: 2, sm: 8, md: 8, lg: 0}}
              paddingBottom={{xs: 5, md: 0, lg: 0}}  maxWidth={1200} id={"memberships"}>
            <Grid item xs={12}>
                <a id={"mint"}>
                   <Typography variant={ matches ? "h2" : "h4"} gutterBottom={true} paragraph={true} fontWeight={400}  color={"#ba282f"} component={"span"}>
                       <Box fontWeight={100} color={"#80828d"} component={"span"}>Join</Box> Blood Hammer <Box fontWeight={100}  color={"#80828d"} component={"span"}>Daojo</Box>
                    </Typography>
                </a>
            </Grid>
            <ItemContainer item xs={12} md={6}>
                <Item padding={2.5}>
                    <MembershipTrainer/>
                </Item>
            </ItemContainer>
            <ItemContainer container item xs={12} md={6}>
                <Item padding={2.5}>
                    <MembershipManager/>
                </Item>
            </ItemContainer>
        </Grid>

};

export default MembershipPanel;
