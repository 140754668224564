import Grid from "@mui/material/Grid";
import React from "react";
import {ParallaxBanner, ParallaxProvider} from "react-scroll-parallax";
import './StealthPanel.css';
import bg from '../assets/images/mat-background.svg';
import girl from '../assets/images/its-a-secret-girl.svg';
import {BrowserView, MobileView} from 'react-device-detect';

const StealthPanel = () => {
   return  <Grid container spacing={0}>
        <Grid item xs={12} height={"75vmin"}/>
        <Grid item xs={12}>
            <BrowserView>
                <div className="stealth">
                    <div className="stealth_shh">
                        <div className="stealth_text"/>
                    </div>
                </div>
            </BrowserView>
            <MobileView>
                <ParallaxProvider>
                    <ParallaxBanner
                        layers={[
                            {image: bg, speed: 0, expanded: true},

                            {image: girl, speed: -5},
                            {
                                children: (
                                    <div className={"stealth_text"}/>
                                ), speed: 0, expanded: true
                            },

                        ]}
                        style={{height: "30vh", position: "relative"}}
                    />
                </ParallaxProvider>
            </MobileView>
        </Grid>
        <Grid item xs={12} height={"75vmin"}/>
    </Grid>;
}

export default StealthPanel;
