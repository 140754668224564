import {Grid, Typography} from "@mui/material";
import React from "react";
import './DaojoPanel.css';
import daojoLocks from "./assets/images/daojo-locked.svg";
import bhIcon from "./assets/images/bhammer-icon.svg";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from "@mui/material/styles";

const DaojoPanel = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    return <Grid container spacing={0} className={"daojo-background"} padding={3} paddingTop={{xs: 8, sm: 16}}>
        <Grid xs={12} flexGrow={1}/>
        <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
            <Typography variant={matches ? "h2" : "h3"} color={"#fff"} fontWeight={900}>
                Blood Hammer
            </Typography>
            <Typography variant={matches ? "h3" : "h4"} color={"#fff"} fontWeight={100}>
                Daojo
            </Typography>
            <img src={bhIcon}  style={{
                paddingTop: 10,
                width: 80,
                height: "auto",
            }}/>
        </Grid>
        <Grid item xs={12} paddingTop={2} display={"flex"}
              flexDirection={"column"} alignItems={"center"}>
            <Typography paragraph={true} variant={"h6"}>
                Blood Hammer Daojo is our first team.
            </Typography>
            <Typography paragraph={true} paddingBottom={2} maxWidth={640}>
                To join a team you purchase a Membership. Daojo members vote
                to configure & equip an AI fighter — your Champion.
            </Typography>
            <Grid xs={12} flexGrow={1}/>

            <EmojiEventsIcon style={{
                width: "auto",
                height: matches ? 50 : 40,
                color: "#fab422",
            }}/>
            <Typography paragraph={true} maxWidth={640}>
                As a Daojo member, you earn exclusive rewards &
                share in your team's cash & prizes.
            </Typography>
        </Grid>
        <Grid xs={12} flexGrow={1}/>
        <Grid item xs={12} padding={matches ? "10%": 0 } paddingTop={2} >
            <img src={daojoLocks} style={{
                width: "100%",
                paddingBottom: 10,
                maxWidth: 768,
            }}/>
            <Typography >
                There will be 10 Daojos — each Daojo priced higher than the last.
            </Typography>
            <Typography variant={"caption"}  color={"#80828d"} textTransform={"uppercase"} >
                Next Daojo drops in late may
            </Typography>
        </Grid>
    </Grid>
}

export default DaojoPanel;

/*
   <Box>
            <Grid item xs={12} >
                <Typography variant={"h3"} gutterBottom={true} paddingTop={8}
                            paragraph={true} fontWeight={100}  color={"#80828d"} textTransform={"uppercase"}>
                    The Daojos
                </Typography>
                <img src={timeline} style={{
                    width: "100%"
                }}/>
                <Typography  color={"#80828d"} textTransform={"uppercase"} component={"div"}>
                    Next Daojo drops in
                    <Typography color={"#aeb0be"}>
                        late May
                    </Typography>
                </Typography>
            </Grid>

            <Typography
                fontSize={{xs: "10vmin", sm: "6vmin"}}
                paddingBottom={{xs: 1, sm: 5}} paddingTop={{xs: 5, sm: 30}} style={{
                textTransform: "uppercase",
                fontWeight: 100,
                color: "#e71414",
            }}>
                <span style={{color: "#e71414"}}>Welcome to the<br/>
                    Pentagramagon</span>
            </Typography>
            <Typography paddingBottom={5} paddingLeft={2} paddingRight={2} style={{
                margin: 'auto',
                maxWidth: 600,
            }}>
                The SFC community is filled with gamers, professionals athletes, sports fans, streamers, and web3
                enthusiasts — all focused on our shared goal — creating the next generation of sports
                entertainment! Our Discord will be your portal to the community and your Daojo. Connect with other
                Members and fans to collaborate, strategize, or just chill with friends in the SFC community.
            </Typography>
            <DiscordButton backgroundColor={"#e52026"}/>
            <Typography paddingTop={1} color={"rgba(255,255,255,0.50)"}>
                Dank memes are always welcome.
            </Typography>
        </Box>
        <Box flexGrow={1}/>
        <Footer/>
 */


