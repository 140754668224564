import {Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";

interface MembershipCostProps {
    total: number;
    cost: number;
}

const MembershipCost = ({total, cost}: MembershipCostProps) => {
    return <Box>
        <Typography variant={"subtitle1"} style={{
            paddingTop: 4,
            lineHeight: '1em'
        }}>
            {total.toLocaleString()} Total
        </Typography>
        <Typography
            fontWeight={500}
            variant={"caption"}>
            <span style={{color: "#50e558"}}>{cost} ETH</span>
        </Typography>
    </Box>;
}

interface MembershipCardProps extends MembershipCostProps {
    name: any;
    type: string;
    img: any;
    color: any;
}

function CardInfo({name, type, total, cost, color}: Omit<MembershipCardProps, "img">) {
    return <Box textAlign={"right"} style={{
        textTransform: "uppercase",
        height: '100%',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",

    }}>
        <Box paddingRight={{xs: 0, sm: 3}}>
            <Typography variant={"body1"} color={color} fontWeight={"bold"} style={{
                color: color
            }}>
                Daojo {type}
            </Typography>
            <Typography variant={"h6"} style={{
                fontWeight: 700,
                lineHeight: "1em"
            }} paddingBottom={2}>
                {name}
            </Typography>
            <MembershipCost total={total} cost={cost}/>
        </Box>

    </Box>;
}

const MembershipCard = ({img, ...rest}: MembershipCardProps) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    return <Grid container paddingBottom={6} paddingTop={4} spacing={2}>
        <Grid item xs={6} paddingRight={0}>
            <CardInfo {...rest}/>
        </Grid>
        <Grid
            item
            xs={6}
            textAlign={"left"}
        >
            <img src={img} style={{
                width: "80%",
                objectFit: "contain",
                marginLeft: matches ? 8 : 0,
            }} alt={"SFC Trainer Membership"}/>
        </Grid>
    </Grid>;
}

export default MembershipCard;
