import React, {PropsWithChildren} from 'react';
import {AccordionSummaryProps, Container, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import evilplan from "./assets/images/evilplan-logo.svg";
import {Link} from "react-router-dom";
import {styled} from "@mui/material/styles";
import MuiAccordion, {AccordionProps} from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {DISCORD_BASEMENT, DISCORD_INVITE, WHITELIST_LINK} from "./components/constants";
import {Bold} from './components/Bold';
import {HelpDesk} from "./components/HelpDesk";

export const Basement = () => <span style={{whiteSpace: "nowrap"}}>
    #🚪&#8209;<a href={DISCORD_BASEMENT}>basement</a>
</span>;

interface ItemProps extends PropsWithChildren<any> {
    title: string;
}

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
        {...props}
    />
))(({theme}) => ({
    backgroundColor: "transparent",
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(-90deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(0),
        marginBottom: 0,
    },
}));


const Item = ({nested, title, expanded, handleChange, children, ...rest}: ItemProps) => {
    return <Accordion style={{
        color: "#fff",
        background: "transparent",
        boxShadow: "none",
        border: "none",
        backgroundColor: "transparent",
        marginRight: 0,
        paddingRight: 0,
        marginBottom: 10,
    }}  {...rest} expanded={true}>
        <AccordionSummary
            expandIcon={undefined}
        >
            <Typography variant={"h4"} gutterBottom={true}>
                {title}
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography variant={"body1"} component={"div"}
                        color={"#cdd1de"}
            >
                {children}
            </Typography>
        </AccordionDetails>
    </Accordion>
}

const BulletList = ({children}: PropsWithChildren<any>) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    return <ul style={{
        padding: 20,
        paddingBottom: 0,
        paddingLeft: matches ? 20 : 40,
        lineHeight: matches ? "unset" : "2em"
    }}>
        {children}
    </ul>
}

const FAQPanel = () => {

    return <Grid item xs={12} paddingTop={{xs: 5, sm: 15}} paddingBottom={{xs: 5, sm: 15}} style={{
        textAlign: "left"
    }}>
        <Container style={{
            maxWidth: 1024,
            minWidth: 320,
        }}>
            <a id="faq">
                <Typography variant={"h1"} fontWeight={700} color={"#fff"} style={{
                    textAlign: "center"
                }} paddingBottom={{xs: 5, sm: 8}}>
                    &mdash; FAQ &mdash;
                </Typography>
            </a>

            <Item title={"What is Secret Fight Club?"}>
                <Typography paragraph={true}>
                    <img src={evilplan} style={{
                        verticalAlign: "middle",
                        height: 'auto',
                        width: "1em",
                        marginRight: '0.4em',
                        paddingBottom: "0.2em",
                    }} alt={"EvilPlan Inc."}/>
                    <Bold>Secret Fight Club</Bold> (SFC) is the world’s first
                    DAO fighting league. In <Bold>Secret Fight Club</Bold> you purchase a
                    Membership to join one of 10 teams, called a <Bold>Daojo</Bold>.
                    Together with <Bold>thousands</Bold> of your closest friends, your Daojo
                    designs, builds, equips, and trains <Bold>one</Bold> Champion —
                    an <Bold> autonomous</Bold> Artificially Intelligent (AI) Robot Fighter.
                    Daojos <Bold>compete</Bold> against each other in live online
                    <Bold> streaming tournaments</Bold> for the whole world. You will cheer
                    alongside your fans as your Champion battles it out for <Bold>cash</Bold>,
                    <Bold> prizes</Bold>, & of course, <Bold>pizza</Bold>.
                </Typography>
                <Typography paragraph={true}>
                    Secret Fight Club is not just democratizing the management of a sports franchise — we are
                    democratizing the entire revenue structure, from top to bottom.
                </Typography>
                <Typography paragraph={true} component={"div"}>
                    If you would like to <Bold>participate in the presale</Bold> for the Blood Hammer Daojo NFT
                    Memberships, you can <Bold>get blacklisted</Bold> <a
                    href={WHITELIST_LINK}>here</a>.
                    <Typography variant={"caption"} marginTop={1} component={"div"} color={"rgba(255,255,255,0.50)"}>
                        Requires an minimum verifiable balance of <span style={{
                        color: "rgba(255,255,255,0.75)"
                    }}>0.25 ETH</span> (the price of an SFC Trainer Membership).
                    </Typography>
                </Typography>
                <Typography paragraph={true}>
                    If you need help, you can message our team from the <HelpDesk/> channel in <a
                    href={DISCORD_INVITE}>our Discord</a> for support.
                </Typography>
            </Item>


            <Item title={"What is a Daojo?"}>
                <Typography paragraph={true}>
                    In Secret Fight Club, you buy an <Bold>NFT Membership</Bold> to join a team, called
                    a "<Bold>Daojo</Bold>". Together with the other members of your Daojo, you design, build, equip,
                    and train an autonomous AI Robot Fighter.
                </Typography>
                <Typography paragraph={true}>
                    This fighter is your Daojo's Champion. Members of a Daojo can vote on things like what NFTs
                    items are
                    used to equip and modify their AI fighter or make cosmetic changes. Using your Champion, your
                    Daojo will
                    battle it out on stream against other Daojos in a series of tournaments for cash, prizes, and
                    pizza.
                </Typography>
                <Typography paragraph={true}>
                    With Secret Fight Club — <Bold>the meta is the game</Bold>.
                </Typography>
                <Typography paragraph={true}>
                    Like a DAO, a Daojo operates through a proposal and voting structure like a Board of Directors.
                </Typography>
                <Typography paragraph={true}>
                    However, the decentralization of web3 allows a Daojo to easily scale these processes to
                    thousands or
                    even millions of members. In Secret Fight Club, the Blood Hammer Daojo has a <Bold>total
                    supply</Bold> of <Bold>thousands</Bold> memberships.
                </Typography>
            </Item>

            <Item title={"What is a Membership?"}>
                <Typography paragraph={true}>

                    The <Bold>Blood Hammer Daojo</Bold> is the first Daojo in the SFC. You are buying
                    an <Bold>NFT Membership</Bold> in that Daojo. The <Bold>pricing & supply</Bold> of Memberships in
                    the
                    Blood Hammer Daojo are structured <Bold>for early adopters</Bold>.
                </Typography>

                <Typography paragraph={true}>

                    NFT Memberships for the Blood Hammer Daojo will be issued as <Bold>ERC-721A</Bold> Non-fungible
                    Tokens on the Ethereum blockchain and will come in two tiers: “<Bold>Manager</Bold>” and
                    “<Bold>Trainer</Bold>”. Each Membership tier will be on a separate ERC-721A smart contract (to save
                    on gas and for technical reasons).
                </Typography>

                <Typography paragraph={true}>

                    Managers will have additional access, functionality, and decision-making power in the form of
                    extended
                    voting and governance rights. There will be <Bold>1,337</Bold> Manager Memberships per Daojo. Each
                    Manager comes with <Bold>4x</Bold> voting rights and cost <Bold>0.75 ETH</Bold> each,
                    representing <Bold>63%</Bold> of the voting power.
                </Typography>

                <Typography paragraph={true}>

                    Trainer Memberships will cost <Bold>0.25 ETH</Bold> each and come with <Bold>1</Bold> vote.
                    There will be <Bold>3,142</Bold> of these.
                </Typography>

                <Typography paragraph={true} component={"div"}>
                    If you would like to <Bold>participate in the presale</Bold> for the Blood Hammer Daojo NFT
                    Memberships, you can <Bold>get blacklisted</Bold> <a
                    href={WHITELIST_LINK}>here</a>.
                    <Typography variant={"caption"} marginTop={1} component={"div"} color={"rgba(255,255,255,0.50)"}>
                        Requires an minimum verifiable balance of <span style={{
                        color: "rgba(255,255,255,0.75)"
                    }}>0.25 ETH</span> (the price of an SFC Trainer Membership).
                    </Typography>
                </Typography>
            </Item>

            <Item title={"Is this a \"Pay to Earn\" game?"}>
                <Typography paragraph={true}>
                    <Bold>No</Bold>. At least, not directly. It's <Bold>better</Bold>. One of the reasons we
                    are <Bold>so excited</Bold> about the Daojo model within SFC is the <Bold>unique revenue
                    opportunities</Bold> it unlocks.
                </Typography>
                <Typography paragraph={true}>
                    Beyond the <Bold>direct revenue</Bold> from the Prize Pools, we anticipate that over time
                    successful Daojos & their Champions will attract <Bold>sponsors</Bold> and other revenue
                    opportunities
                    on and off the platform. If a major brand wants to logo your Champion,
                    or sponsor your Daojo, that <Bold>revenue flows directly back to you</Bold>, the Members of the
                    Daojo (minus a
                    small platform fee).
                </Typography>
                <Typography paragraph={true}>
                    In other words, we are not just <Bold>democratizing</Bold> the management function of a sports
                    franchise — <Bold>we democratizing the entire revenue structure, from top to bottom</Bold>.
                </Typography>
            </Item>


            <Item title={"I. Want. In."}>
                <Typography paragraph={true} component={"div"}>
                    To join the <Bold>blacklist</Bold> for the <Bold>Blood Hammer Daojo NFT Membership
                    presale</Bold> click <a href={WHITELIST_LINK}>here</a>.
                    <Typography variant={"caption"} marginTop={1} component={"div"} color={"rgba(255,255,255,0.50)"}>
                        Requires an minimum verifiable balance of <span style={{
                        color: "rgba(255,255,255,0.75)"
                    }}>0.25 ETH</span> (the price of an SFC Trainer Membership).
                    </Typography>
                </Typography>
                <Typography paragraph={true}>
                    If you need help, you can message our team from the <HelpDesk/> channel in <a
                    href={DISCORD_INVITE}>our Discord</a> for support.
                </Typography>


                <Typography variant={"h6"} component={"div"}>
                    I. Also. Want. In. But. Don’t. Have. 0.25 ETH.
                    <Typography paragraph={true}>
                        Fear not, there are <Bold>many free ways</Bold> to play and engage with Secret Fight Club.
                    </Typography>
                </Typography>
            </Item>

            <Item title={"What’s an NFT?"}>
                An NFT stands for "Non-Fungible Token" and is a fancy way of saying it’s a unique, one-of-a-kind digital
                item that users can buy, own, and trade. Some NFTs main functions are to be digital art and look cool,
                some offer additional utility. Think of ours like a rare piece of art that can also act as a
                "membership"
                card tied to a specific Daojo (DAO) which gets you access to members-only perks.
            </Item>

            <Item title={"What’s a DAO?"}>
                A DAO stands for "Decentralized Autonomous Organization" and is a fancy way of saying it’s a member
                organization that’s governed by code instead of leaders. DAOs typically use smart contracts in place of
                traditional structures to coordinate the efforts and resources of many towards common aims.
            </Item>

            <Item title={"Use of Funds"}>
                <Typography paragraph={true}>

                    This project and the core team has been completely bootstrapped. We have not taken a dime of outside
                    money or VC and have no source of external funding. The benefit is that we are not beholden to
                    shareholders or providing quick returns.
                </Typography>

                <Typography paragraph={true}>

                    We have deigned the mint of the Blood Hammer Daojo to enable us to raise enough funds to cover
                    operations for approximately 18 months. This will allow us to expand the team and provide the time
                    we
                    need to launch the existing game, stand up the remaining Daojos, and finally automate their
                    integration
                    with the game’s NFT item system.
                </Typography>

                <Typography paragraph={true}>

                    While we fully expect there to be a vibrant secondary market for memberships (and items!), we do not
                    think the long-term value is in a quick 3x or even 10x return. This is about standing up an entire
                    economic system with multiple layers of economic incentives that dovetail into, and reinforce, each
                    other.
                </Typography>

                <Typography paragraph={true} component={"div"}>

                    The proceeds from <b>any sale</b> (including the proceeds from this or any future Membership Card
                    sales), net of taxes, will be used to fund EvilPlan Inc. operations, including, but not limited
                    to...

                    <BulletList>
                        <li>
                            Operations including, but not limited to, employee salaries, contractors, 3rd party vendors,
                            and facilities costs
                        </li>
                        <li>
                            Product development & engineering
                        </li>
                        <li>
                            Game development including any new, or existing, original IP
                        </li>
                        <li>
                            Deployment, hosting, and network infrastructure
                        </li>
                        <li>
                            Daojo infrastructure setup, operational costs, and fees
                        </li>
                        <li>
                            Legal, compliance and accounting
                        </li>
                        <li>
                            Marketing, merchandising, partnerships and sales
                        </li>
                        <li>
                            Community development
                        </li>
                        <li>
                            Future internal or community initiatives
                        </li>
                    </BulletList>
                </Typography>
            </Item>

            <Item title={"About EvilPlan"}>

                EvilPlan’s core team is comprised of industry professionals from gaming, software, and sports
                entertainment with an average of +10 years professional experience. The founding members have worked in
                executive or senior leadership roles at their own startups, FAANG, and other high-value public
                companies.

            </Item>

            <Item title={"What are the Terms of Sale?"}>
                You can view the full Terms of Sale <Link to={"/legal"}>here</Link>.
            </Item>

            <Item title={"Want to learn more?"}>
                You can <Link to={"src/secret/Index.tsx"}>RTFM</Link>.
            </Item>


        </Container>

    </Grid>
}
export default FAQPanel;
