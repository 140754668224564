import {Box, Grid} from "@mui/material";
import React from "react";
import sfc from "./assets/images/sfc-wordmark.svg";
// @ts-ignore
import gameplay from "./assets/video/gameplay.mp4";
import './SplashPanel.css';
import DiscordButton from "./components/DiscordButton";
import JoinButton from "./components/JoinButton";
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import WatchButton from "./components/WatchButton";

const SplashPanel = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <Grid container spacing={0}>
            <Grid item xs={12} height={"100vmin"} style={{
                position: "relative",
            }}>
                <video autoPlay muted loop playsInline className="splash-gameplay">
                    <source src={gameplay} type="video/mp4"/>
                </video>
                <div className={"splash-dimmer"}/>
                <img src={sfc} className={"splash-logo"} style={{
                    top: matches ? "45%" : "30%",
                }} alt={"Secret Fight Club"}/>
                <Box className={"splash-buttons"}  display={"flex"} flexDirection={matches ? "row" : "column"}>
                    <JoinButton url={"#mint"}/>
                    <Box minWidth={60} minHeight={20}/>
                    <WatchButton url={"#gameplay"}/>
                </Box>
            </Grid>

        </Grid>
    );
}

export default SplashPanel;

/*

  const scrollToMemberships = () => {
        scroller.scrollTo("memberships", {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
        });
    };

      <Grid item xs={12} justifyContent={"center"} paddingBottom={4}>
                <a id="mint">

                </a>
            </Grid>
 */