import * as React from 'react';
import {PropsWithChildren} from 'react';
import {AccordionSummaryProps, Container, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {styled} from "@mui/material/styles";
import evilplan from "../assets/images/evilplan-logo.svg";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Box from "@mui/material/Box";
import {HashLink} from "react-router-hash-link";

import {HelpDesk} from "../components/HelpDesk";
import {Bold} from "../components/Bold";
import ScrollToTop from "../components/ScrollToTop";
import {DISCORD_INVITE, WHITELIST_LINK} from "../components/constants";

interface ItemProps extends PropsWithChildren<any> {
    title: string;
    expanded?: string | boolean;
    handleChange?: (x: string) => any;
    nested?: boolean;
}

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary

        expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
        {...props}
    />
))(({theme}) => ({
    backgroundColor: "transparent",
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(-90deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));


const Item = ({nested, title, expanded, handleChange, children, ...rest}: ItemProps) => {
    const open = Boolean(expanded === title);
    return <Accordion style={{
        color: "#fff",
        background: "transparent",
        boxShadow: "none",
        border: "none",
        backgroundColor: "transparent",
        marginRight: 0,
        paddingRight: 0,
        marginLeft: nested ? "unset" : -26,
    }} expanded={nested ? open : undefined} onChange={handleChange?.(title)} {...rest}>
        <AccordionSummary
            expandIcon={nested ? <ExpandMoreIcon style={{
                color: "#d51111",
            }}/> : undefined}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography variant={nested ? "h6" : "h4"} gutterBottom={true}>
                {title}
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography variant={"body1"} component={"div"}
                        color={"#cdd1de"}
            marginLeft={1}>
                {children}
            </Typography>
        </AccordionDetails>
    </Accordion>
}

const BulletList = ({children}: PropsWithChildren<any>) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    return <ul style={{
        padding: 20,
        paddingBottom: 0,
        paddingLeft: matches ? 20 : 40,
        lineHeight: matches ? "unset" : "2em"
    }}>
        {children}
    </ul>
}

const ManualPage = () => {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return <Grid item xs={12} paddingTop={{xs: 5, sm: 15}} paddingBottom={{xs: 5, sm: 15}} style={{
        textAlign: "left"
    }}>
        <ScrollToTop/>
        <Container style={{
            maxWidth: 1024,
            minWidth: 320,
        }}>
            <Box display={"flex"}
                 justifyContent={"space-between"}
                 paddingBottom={6}
                 marginTop={2}>
                <img src={evilplan} style={{
                    height: '3em',
                    width: "auto",
                    marginRight: '0.4em',
                }} alt={"EvilPlan Inc."}/>

                <Typography variant={"h1"} fontWeight={700} style={{
                    textAlign: "center",
                    color: "#fff"
                }} paddingBottom={{xs: 5, sm: 10}}>
                    Secret Fight Club
                    <Typography paddingTop={2} component={"div"} textTransform={"uppercase"} variant={"h2"} fontWeight={50}
                    color={"#373b46"}>
                        Secret Manual
                    </Typography>
                </Typography>

                <Typography variant={"caption"}>
                    ⬅️ <HashLink to={"/#mint"}>Home</HashLink>
                </Typography>
            </Box>


            <Typography variant={"h3"} gutterBottom={true}>
                What is Secret Fight Club?
            </Typography>
            <Typography gutterBottom={true} paragraph={true} style={{
                color: "#cdd1de",
            }}>
                <img src={evilplan} style={{
                    verticalAlign: "middle",
                    height: 'auto',
                    width: "1em",
                    marginRight: '0.4em',
                    paddingBottom: "0.2em",
                }} alt={"EvilPlan Inc."}/>
                <span style={{fontWeight: 420, color: "#fff"}}>Secret Fight Club</span> (SFC) is the world’s first
                DAO fighting league. In <span style={{color: "#fff"}}>Secret Fight Club</span> you purchase a
                Membership to join one of 10 teams, called a <span style={{color: "#fff"}}>Daojo</span>.
                Together with <span style={{color: "#fff"}}>thousands</span> of your closest friends, your Daojo
                designs, builds, equips, and trains <span style={{color: "#fff"}}>one</span> Champion —
                an <span style={{color: "#fff"}}> autonomous</span> Artificially Intelligent (AI) Robot Fighter.
                Daojos <span style={{color: "#fff"}}>compete</span> against each other in live online
                <span style={{color: "#fff"}}> streaming tournaments</span> for the whole world. You will cheer
                alongside your fans as your Champion battles it out for <span style={{color: "#fff"}}>cash</span>,
                <span style={{color: "#fff"}}> prizes</span>, & of course, <span style={{color: "#fff"}}>pizza</span>.
                </Typography>
            <Typography paragraph={true}>
                Secret Fight Club is not just democratizing the management of a sports franchise — we are democratizing
                the entire revenue structure, from top to bottom.
            </Typography>
            <Typography paragraph={true} component={"div"}>
                If you would like to <Bold>participate in the presale</Bold> for the Blood Hammer Daojo NFT
                Memberships, you can <Bold>get blacklisted</Bold> <a
                href={WHITELIST_LINK}>here</a>.
                <Typography variant={"caption"} marginTop={1} component={"div"} color={"rgba(255,255,255,0.50)"}>
                    Requires an minimum verifiable balance of <span style={{
                    color: "rgba(255,255,255,0.75)"
                }}>0.25 ETH</span> (the price of an SFC Trainer Membership).
                </Typography>

            </Typography>

            <Typography paragraph={true}>
                If you need help, you can message our team from the <HelpDesk/> channel in <a
                href={DISCORD_INVITE}>our Discord</a> for support.
            </Typography>

            <Item title={"How does Secret Fight Club work?"}>
                <Typography paragraph={true}>
                In Secret Fight Club, you buy a Membership to join a team, called a "Daojo" (DAO + Dojo). Together
                with the other members of your Daojo, you design, build, equip, and train an autonomous AI fighter.
                This fighter is your Daojo's Champion. Members of a Daojo can vote on things like what NFTs items are
                used to equip and modify their AI fighter or make cosmetic changes. Using your Champion, your Daojo will
                battle it out on stream against other Daojos in a series of tournaments for cash, prizes, and pizza.

                </Typography>

                <Typography paragraph={true}>
                With Secret Fight Club — <b>the meta is the game</b>.
                </Typography>



                <Item nested title={"DAOs"} expanded={expanded} handleChange={handleChange}>
                    <Typography paragraph={true}>
                    A DAO stands for "Decentralized Autonomous Organization" and is a fancy way of saying it’s a member
                    organization that’s governed by code instead of leaders. DAOs typically use smart contracts in place
                    of
                    traditional structures to coordinate the efforts and resources of many towards common aims.
                    </Typography>

                    <Typography paragraph={true}>
                    DAOs are decentralized. This means that the organizational elements (such as the rules and bylaws),
                    as well as the enforcement mechanisms, exist on, and are protected by, the Ethereum network itself.
                    Like Bitcoin, this distribution guarantees that no single point of attack exists, making it
                    extremely difficult, if not nearly impossible, for an attacker to gain control.
                    </Typography>

                    <Typography paragraph={true}>
                    Like their traditional counterparts, DAOs typically operate through a proposal and voting structure
                    like a Board of Directors. However, the decentralization of web3 allows a DAO to easily scale these
                    processes to thousands or even millions of members.
                    </Typography>
                </Item>

                <Item nested title={"Autonomous AI Fighters"} expanded={expanded} handleChange={handleChange}>
                    <Typography paragraph={true}>
                    During a match, there is no direct control of the Champion by anyone. The AI Robot Fighter is
                    entirely autonomous. This means your Champion will fight on its own, learning and growing over time
                    based on its own experiences. Instead of direct control, the gameplay is centered around strategy
                    and planning. Daojos will create, vote, and execute on strategy as an organization.
                    </Typography>
                        <Typography paragraph={true}>
                    Think the other Daojos are going to equip Missiles? Consider blast resistant, but heavier armor.
                    Your fighter will move more slowly but take less damage.
                        </Typography>
                            <Typography paragraph={true}>
                    Think the other Daojos are relying more on EMP resistant cores? A Freeze Spell Ultimate powered by
                    Arcane Energy and stronger kinetic kicks may be just the thing. Your fighter will have longer
                    cooldowns and a bigger frame, but if you connect, you’ll deal more damage.
                            </Typography>
                                <Typography paragraph={true}>
                    In SFC, the meta game is the game. There is no correct way to play the game, only outsmarting the
                    other Daojos.
                                </Typography>
                </Item>

                <Item nested title={"The Meta Game"} expanded={expanded} handleChange={handleChange}>
                    Because these are AIs and not deterministic, you cannot precisely control or predict exactly what
                    will happen, but you can influence it – like the coach in the corner of a boxing match – you can
                    have influence (training, technique, fitness, etc.) but not direct control. Because these are
                    learning AIs, a not just RNG, the outcomes are also not random. Just like IRL, your Daojo’s
                    strategic choices, combined with an AI Fighters own experience and skills, ultimately determines who
                    wins.
                </Item>

                <Item nested title={"League Organization"} expanded={expanded} handleChange={handleChange}>
                    All the Daojos in the SFC League complete in a single circuit over the course of a season.
                    <br/><br/>
                    The SFC World Championship season consists of a series of fights, known as Ranked Matches, held in
                    various virtual locations, each with different environmental effects and scenery such as the Island
                    Base, Arctic Base, or YUMAD Base.
                    <br/><br/>
                    A points scoring system is used for each Ranked Match held over the course of the SFC season to
                    determine the outcome of two seasonal championships, one for AI Champions (World Fighters’
                    Championship) and one for Daojos (World Daojos’ Championship).
                    <br/><br/>
                    Each AI Robot Fighter accumulates championship points individually in the World Fighters’
                    Championship and collectively for the Daojo they compete for in the World Daojos’ Championship.
                    <br/><br/>
                    At the end of the season, the 5 Daojos with the most points square off against each other in a final
                    tournament to determine the winner. The top two Daojo are awarded an automatic prize and double
                    elimination (if necessary).
                </Item>

                <Item nested title={"Exhibition Matches"} expanded={expanded} handleChange={handleChange}>
                    Unlike Ranked Matches, in an Exhibition Match, neither the AI Robot Fighter, nor the Daojo they
                    compete for, accumulates any championship points. Since the AI Robot Fighters are not controlled by
                    players, there will be continuous matches, both to provide entertainment for spectators, as well as
                    training data for the AI.
                </Item>

                <Item nested title={"Audience Focus"} expanded={expanded} handleChange={handleChange}>
                    With Secret Fight Club the entire audience for a Match (fans, Daojo Members, and spectators) all
                    watch the same Match at the same time, together. Only one Match (Ranked or Exhibition) occurs at a
                    time and each Match is streamed live online as it happens, in real time.
                    <br/><br/>
                    There is no necessary hardware or software install, any Match can be watched live on
                    the Secret Fight Club <a href={"https://twitch.com/secretfightclub"}>Twitch stream</a> or
                    on EvilPlan's <a href={"https://www.twitch.tv/evilplaninc"}>Twitch channel</a>.
                </Item>

                <Item nested title={"Wrestling Meets Car Racing"} expanded={expanded} handleChange={handleChange}>
                    Yes.
                </Item>

            </Item>

            <Item title={"What is a Daojo?"}>
                <Typography paragraph={true}>
                In SFC we use a DAO as the structure for a Daojo, providing both a membership & governance structure,
                and a game mechanic.
                </Typography>
                    <Typography paragraph={true}>
                To join and participate in a Daojo you must purchase and hold a Membership NFT. Membership NFTs are
                specific to a Daojo, meaning you must hold an NFT Membership for Daojo “A” to participate in Daojo “A”,
                and an NFT Membership for Daojo “B” to participate in Daojo “B”, and so on.
                    </Typography>

                <Item nested title={"Operations"} expanded={expanded} handleChange={handleChange}>
                    As a Daojo Member, you will have both governance & voting rights and be entitled to a proportional
                    revenue share in your Daojo. The exact form the governance takes, or specific processes a Daojo
                    uses, will up to you and the other voting members to decide. In Secret Fight Club, how you organize
                    and run your Daojo along with the other members is part of the game.
                </Item>

                <Item nested title={"Design & Management"} expanded={expanded} handleChange={handleChange}>
                    <Typography paragraph={true}>
                    Generally, as a DAO this means that you and the other Members of your Daojo will propose strategy &
                    design choices for your AI Robot Fighter based on the current meta of the game and then vote on your
                    choices to make changes prior to the start of a Match. Once the Match starts, you and the other
                    Members will see how your choices played out and ultimately which AI Robot Fighter was victorious.
                    </Typography>
                        <Typography paragraph={true}>
                    The Daojo can make modifications to its Champion using items that can be equipped or applied. The
                    items will be made available as NFTs to be purchased. Daojos can customize all aspects of their
                    Champion from the cosmetic look & feel (clothing, glasses, other accessories, etc.), to the design
                    (treads, armor, rocket booster, etc.), to the equipment (missiles, magic wand, lasers), to their
                    Ultimate, special moves, buffs, and personality. While most items will be available to all Daojos,
                    some specialty items will be limited to use by certain Daojos.
                        </Typography>
                            <Typography paragraph={true}>
                    Customized cosmetics can be created at special requests. These can be branding unique to your Daojo,
                    or through sponsorships.
                            </Typography>
                </Item>

                <Item nested title={"Why DAOs"} expanded={expanded} handleChange={handleChange}>
                    <Typography paragraph={true}>
                    By using a DAO, we provide an easily enforceable way to standardize participation, access, and
                    rewards on the SFC platform.
                    </Typography>
                        <Typography paragraph={true}>
                    DAOs use a cryptographically secure public ledger for settlement and as a as a source of truth,
                    traditionally vulnerable organizational processes such as vote counting, execution, and enforcement
                    are fundamentally safer. DAOs seek to eliminate human error and protect against manipulation. By
                    placing decision-making power into the hands of an automated system and a crowdsourced process, DAOs
                    remove the ability for bad actors to take to corrupt the organization.
                        </Typography>
                            <Typography paragraph={true}>
                    Although relatively new, DAOs have quickly been adopted by the wider web3 and financial communities.
                    Due to their inherent security DAOs are now used to manage billions of dollars worldwide across a
                    variety of funds, investment vehicles, companies, and organizations.
                            </Typography>
                </Item>

                <Item nested title={"Why NFT Memberships"} expanded={expanded} handleChange={handleChange}>
                    <Typography paragraph={true}>
                    An NFT stands for "Non-Fungible Token" and provide a mechanism to records ownership rights on a
                    blockchain digitally. NFTs are unique, one-of-a-kind digital items that users can buy, own, and
                    trade. Many NFTs are used to verify the ownership of digital art, while others offer additional
                    utility like real-world benefits or access. Because an NFT records ownership publicly, owning an NFT
                    can act as a proof of membership – much like a physical access card.
                    </Typography>
                    <Typography paragraph={true}>
                    Recently, a DAO called <a href={"https://linksdao.io"}>LinksDAO</a> developed and successfully
                    proved a model for a consumer-friendly DAO based on NFT Memberships (instead of a limited DAO
                    specific token). The NFT Membership gives the bearer access to, and voting and governance rights in,
                    the DAO. This allows the NFT Membership holders to organize, manage, and transact through the DAO –
                    greatly simplifying the DAO experience.
                    </Typography>
                    <Typography paragraph={true}>
                    With an NFT Membership, the NFT is like a rare piece of art that can also act as a "membership" card
                    tied to a specific DAO which gets you access to members-only perks. More importantly, Membership
                    NFTs have actual utility and inherit value. This is distinct from Art based projects like BAYC but
                    have no intrinsic util and therefore a more volatile assets that more sensitive to consumer fashion
                    taste.
                    </Typography>
                    <Typography paragraph={true}>

                    Our memberships are encapsulated in a standard NFT ERC-721A smart contract and issued on the
                    Ethereum blockchain. As a result, NFT Membership holders have immediate access to well
                    established marketplaces like <a href={"https://opensea.io"}>OpenSea</a>, and more critically, the
                    liquidity of the wide Ethereum ecosystem.
                    </Typography>
                </Item>

                <Item nested title={"Governance Rights"}
                      expanded={expanded} handleChange={handleChange}>
                    The proposal, approval, and execution of non-financial Daojo matters, including, but not limited
                    to...
                    <BulletList>
                        <li>
                            Approval of Daojo functional operating committees
                        </li>
                        <li>
                            Design, management, and training of their AI fighter
                        </li>
                        <li>
                            In-game NFT item procurement, selection process a/o approval, and use as proposed by the
                            relevant
                            operating committee — including, but not limited to, cosmetics, upgrades, equipments, buffs,
                            and mods
                        </li>
                        <li>
                            Daojo rules, including culture, activities, design, and overall vision & strategy
                        </li>
                        <li>
                            Daojo membership rules and policies
                        </li>
                        <li>
                            Marketing, merchandising, partnerships and sales pertaining the their AI fighter, or the
                            Daojo itself.
                        </li>
                    </BulletList>
                </Item>
                <Item nested title={"Tooling & Support"} expanded={expanded} handleChange={handleChange}>
                    <Typography paragraph={true}>
                    While the governance model for a Daojo will be set by its members, EvilPlan will provide tooling and
                    support to facilitate their choices (as best we can) and make general recommendations.
                    </Typography>
                    <Typography paragraph={true}>
                    Daojos are free to determine their own internal organizational principals and processes based on
                    their own membership. This will allow a Daojo to optimize its own operation to best suit its members
                    and their needs.
                </Typography>
                <Typography paragraph={true}>
                    Where appropriate we will share frameworks and best practices among the Daojos to help bootstrap or
                    take advantage of new features or revenue opportunities as they come online.
            </Typography>
                </Item>

                <Item nested title={"10 Daojo Limit"} expanded={expanded} handleChange={handleChange}>
                    <Typography paragraph={true}>

                    To prevent others from thwarting our plans in this regard by diluting you, we intend to tightly
                    control supply of Daojos. Longer term, and at scale, we are more likely to consider a League
                    Commission of Daojos -- which would itself be responsible for any decision to expand the number of
                    participating Daojos.
                    </Typography>
                        <Typography paragraph={true}>

                        While we reserve the right to expand the overall size of this league beyond 10 Daojos, create
                    complimentary leagues, or support additional games, our intention is to promote a vibrant ecosystem
                    operating at a global scale.
                        </Typography>
                            <Typography paragraph={true}>

                            We don’t (and can’t) know what this exact number is, but we can learn a lot from existing sports
                    franchises and have some idea what fans can reasonably support and what makes sense.
                            </Typography>
                                <Typography paragraph={true}>

                                SFC is designed social management and socially competitive features, not individual ownership.
                    Greatly expanding the number of Daojos to something like 1,000 would fragment the audience,
                    dilute the collective value, and undermine the overall ecosystem.
                                </Typography>
                                    <Typography paragraph={true}>

                                    The potential market opportunity and personal satisfaction we get from the change to invent an
                    entirely new genre of gaming and entertainment alongside you is far more attractive to us.
                                    </Typography>
                                        <Typography paragraph={true}>

                                        While others might choose differently, we see the long-term value for all of us being personally and
                    professional at a minimum of 100x over any quick flip at scale.
                                        </Typography>
                </Item>


            </Item>

            <Item title={"What is an NFT Membership?"}>
                <Typography paragraph={true}>

                The Blood Hammer Daojo is the first Daojo in the SFC. You are buying Membership in that Daojo. The
                Blood Hammer Daojo is structured for early adopters.
                </Typography>

                    <Typography paragraph={true}>

                    NFT Memberships for the Blood Hammer Daojo will be issued as ERC-721A Non-fungible Tokens on the
                Ethereum blockchain and will come in two tiers: “Manager” and “Trainer”. Each Membership tier will be on
                a separate ERC-721A smart contract (to save on gas and for technical reasons).
                    </Typography>

                        <Typography paragraph={true}>

                        Managers will have additional access, functionality, and decision-making power in the form of extended
                voting and governance rights. There will be 1,337 Manager Memberships per Daojo. Each Manager comes with
                4x voting rights and cost 0.75 ETH each, representing 63% of the voting power.
                        </Typography>
                            <Typography paragraph={true}>

                            Trainer Memberships will cost 0.25 ETH each and come with 1 vote. There will be 3,142 of these per
                Daojo.
                            </Typography>
                <Typography paragraph={true}  component={"div"}>
                    If you would like to <Bold>participate in the presale</Bold> for the Blood Hammer Daojo NFT
                    Memberships, you can <Bold>get blacklisted</Bold> <a
                    href={WHITELIST_LINK}>here</a>.
                    <Typography variant={"caption"} marginTop={1} component={"div"} color={"rgba(255,255,255,0.50)"}>
                        Requires an minimum verifiable balance of <span style={{
                        color: "rgba(255,255,255,0.75)"
                    }}>0.25 ETH</span> (the price of an SFC Trainer Membership).
                    </Typography>
                </Typography>
                <Typography paragraph={true}>
                    If you need help, you can message our team from the <HelpDesk/> channel in <a
                    href={DISCORD_INVITE}>our Discord</a> for support.
                </Typography>
                <Item nested title={"Presale & Public Mint"} expanded={expanded} handleChange={handleChange}>
                    <Typography paragraph={true}>

                    We are currently preauthorizing addresses for the Blood Hammer Daojo mint (TBA). During the presale,
                    blacklisted addresses will have the opportunity to mint NFT Trainer Memberships (0.25 ETH each) or
                    NFT Manager Memberships (0.75 ETH each) for Blood Hammer Daojo. Wallets will be limited to minting 5
                    Memberships at a time with no limit to the total number of memberships.
                    </Typography>
                        <Typography paragraph={true}>

                        At the conclusion of the presale, Memberships will become available for public mint at the same
                    pricing structure.
                        </Typography>
                            <Typography paragraph={true}>

                    The exact exact format, structure, pricing, and availability of the other Daojos has not been
                    finalized.
                            </Typography>
                </Item>

                <Item nested title={"Voting Rights"} expanded={expanded} handleChange={handleChange}>
                    <Typography paragraph={true}>

                    As members of a Daojo it’s up to you to decide what your AI Robot Fighter looks like, how it acts,
                    what weapons or moves it has, or what buffs or special items are available to it in a fight. Daojo
                    members make these decisions by voting on proposals. Each Trainer Membership you own gives you 1
                    vote, and each Manager Membership you own gives you 4 votes.
                    </Typography>
                    <Typography paragraph={true}>

                    Since the Memberships are themselves NFTs you are free to use those votes however you’d like. Vote
                    directly, proxy your votes to another Daojo member or committee, or simply come along for the ride
                    and abstain.
                    </Typography>
                </Item>

                <Item nested title={"Customization Rights"} expanded={expanded} handleChange={handleChange}>
                    A Members of a Daojo it’s up to you to decide you want to trade less armor (and the increased weight
                    that comes with it), for speed. Or to try out a new Pizza Grenade, or Freeze Spell, a Special Laser
                    Uppercut, or a more aggressive fighting style. There will be a wide variety of NFT items to choose
                    from that allow you to customize almost every aspect of your AI Robot Fighter from armaments &
                    equipment to its physical design, special moves — even your AI’s behavior, personality, & fighting
                    style.
                    <br/><br/>
                    Some NFT will be permanent, some seasonal, or limited to a certain number of uses or on a time
                    decay. Some items will be craftable or have unforeseen effects when interacting with other items in
                    real time. Many items will be nondeterministic, meaning there is a % chance it will do X or Y.
                </Item>

                <Item nested title={"Cosmetic Branding"} expanded={expanded} handleChange={handleChange}>
                    Some NFT Items are purely cosmetic, meaning they will change the look of your AI Fighter (hat,
                    sneakers, cape, color scheme) but not affect its gameplay. These items are more likely to be
                    permanent and replaces other previously used cosmetic items when equipped.
                    <br/><br/>
                    Using cosmetic items Daojos can fully brand their Champions with custom fits, looks, color schemes
                    and logos. Want your fighter to look like a wizard? A technologically advanced battle machine?
                    Wearing the latest kicks? Sporting a custom logo? Or skinned like a NASCAR for a sponsorship deal?
                    It’s entirely up to you and your Daojo.
                </Item>

                <Item nested title={"Can I buy a whole Daojo?"} expanded={expanded} handleChange={handleChange}>
                    Yes.
                    <br/><br/>
                    This opportunity is extremely limited. Organizations looking to extend their brand will receive
                    exclusive access to cosmetic items, equipment, and movement sets. These specialty items will be
                    designed to enable an organization to customize the look & feel of their AI Robot Fighter to reflect
                    their unique brand.
                    <br/><br/>
                    Contact us if your organization wants to own a full Daojo to discuss a pricing and membership
                    structure that meets your needs.
                </Item>

                <Item nested title={"Is this an NFT Project?"} expanded={expanded} handleChange={handleChange}>
                    No. Well, not exactly.
                    <br/><br/>
                    Secret Fight Club is not a typical NFT project. EvilPlan is building the world’s first DAO fighting
                    league, starting with Secret Fight Club. In SFC, the NFT Membership entitles you to participate in
                    your Daojo’s governance and a proportional share of its revenue streams.
                    <br/><br/>
                    Think of it like owning a percentage of a football team or other sports franchise. The value of that
                    ownership stake is not in its inherent scarcity, but in the economic access it unlocks.
                    <br/><br/>
                    Holding a Membership in a Daojo is about that same long-term investment and economic access to the
                    market we are creating and expanding with SFC. Secret Fight Club is creating an entirely new genre
                    of gaming and redefining sports entertainment for fans – not just interactively, but economically.
                    In other words, we are leveraging the technology and power of NFTs and DAOs to deliver maximum
                    utility.
                    <br/><br/>
                    The market opportunity SFC represents is not measured in millions, or billions, or even tens of
                    billions of dollars — this is about hundreds of billions, if not trillions, of dollars in value.
                </Item>
            </Item>


            <Item title={"How do Daojos earn revenue?"}>
                <Typography paragraph={true}>
                One of the reasons we are so excited about the Daojo model within SFC is the unique revenue
                opportunities it unlocks.
                </Typography>
                    <Typography paragraph={true}>
                To give you a quick example -- Normally if an athlete performs well enough to be approached by a band
                for a sponsorship or endorsement deal, the vast majority of that revenue flows to the athlete, as it
                should. In SFC, the athlete is an AI, so if a major brand wants to logo your fighter, that revenue flows
                directly back to you, the Members of the Daojo (minus a small platform fee).
                    </Typography>
                        <Typography paragraph={true}>
                In other words, we are not just democratizing the management function of a sports franchise — but the
                entire revenue structure, from top to bottom.
                        </Typography>

                <Item nested title={"Traditional Models"} expanded={expanded} handleChange={handleChange}>
                    We have intentionally designed the SFC Daojo model to support all the revenue streams available to a
                    traditional sports franchise. Merchandising, fan loyalty programs, even seasonal tickets, are all
                    possible within the Daojo structure. With a Daojo, whatever revenue models you choose to operate or
                    revenue streams you decide to enable – as Members, it’s yours to keep (minus a small platform fee).
                </Item>

                <Item nested title={"Platform Models"} expanded={expanded} handleChange={handleChange}>
                    <Typography paragraph={true}>

                    In addition to Daojo specific revenue models, we are able to unlock platform specific revenue
                    streams in the same way. A percentage of the transaction fees from the fantasy sports contests, NFT
                    item sales, and other platform wide revenue streams will be combined to form the seasonal prize
                    pools for the SFC League. For an example of how this operates at scale, take a look at recent prize
                    pools for The International (Dota 2). Last year the total prize pool was US$40M with a grand prize
                    of over US$18M.
                    </Typography>
                        <Typography paragraph={true}>

                        All the proceeds from any League prize won by a Daojo is shared directly by its members.
                        </Typography>
                </Item>

                <Item nested title={"Not Play to Earn"} expanded={expanded} handleChange={handleChange}>
                    <Typography paragraph={true}>
                    No. At least, not directly.
                    </Typography>
                        <Typography paragraph={true}>
                    This is better.
                        </Typography>
                            <Typography paragraph={true}>
                    When your Daojo does well, your Champion will naturally gain fans and build audience. Beyond the
                    direct revenue from the Prize Pools you presumably have won, we anticipate that over time
                    successful Daojos & their Champions will attract sponsors, receive endorsement deals, and unlock
                    future revenue opportunities (on and off the platform).
                            </Typography>
                </Item>

            </Item>

            <Item title={"I. Want. In."}>
                <Typography paragraph={true}  component={"div"}>
                    To join the <Bold>blacklist</Bold> for the <Bold>Blood Hammer Daojo NFT Membership
                    presale</Bold> click <a href={WHITELIST_LINK}>here</a>.
                    <Typography variant={"caption"} marginTop={1} component={"div"} color={"rgba(255,255,255,0.50)"}>
                        Requires an minimum verifiable balance of <span style={{
                        color: "rgba(255,255,255,0.75)"
                    }}>0.25 ETH</span> (the price of an SFC Trainer Membership).
                    </Typography>
                </Typography>
                <Typography paragraph={true}>
                    If you need help, you can message our team from the <HelpDesk/> channel in <a
                    href={DISCORD_INVITE}>our Discord</a> for support.
                </Typography>


                <Typography variant={"h6"} component={"div"}>
                    I. Also. Want. In. But. Don’t. Have. 0.25 ETH.
                    <Typography paragraph={true}>
                        Fear not, there are <Bold>many free ways</Bold> to play and engage with Secret Fight Club.
                    </Typography>
                </Typography>

                <Item nested title={"Free to Watch"} expanded={expanded} handleChange={handleChange}>
                    Yes, that’s right. If you just want to watch massive organizations
                    and the AI Robot Fighters battle it out on stream, you can do so any time --
                    for free. Just head over to <a href={"https://twitch.com/secretfightclub"}>our Twitch stream</a>,
                    kick back,
                    subscribe to show your support, and enjoy the show.
                </Item>

                <Item nested title={"Play Fantasy Sports"} expanded={expanded} handleChange={handleChange}>
                    Enter a hyper-fast Fantasy Sports contest (1 Match) or a Fantasy Sports tournaments for cash or
                    prizes for an entry fee. How much you want to play for is entirely up to you. $5, $50, or $500 — we
                    will provide the contests and you decide.
                </Item>

                <Item nested title={"Free to Create"} expanded={expanded} handleChange={handleChange}>
                    <Typography paragraph={true}>

                    Join <a href={DISCORD_INVITE}>our Discord</a> to volunteer for our front company
                    designing weapons, equipment, and other items. If your concept is chosen, our team of professional
                    artists and designers turn it into a real in-game NFT item and make it available for purchase by the
                    Daojos.
                    </Typography>
                    <Typography paragraph={true}>

                    We will reward the originator(s) of the item a small portion of the proceeds from the sale their
                    item along with an IRL commemorative keepsake.
                    </Typography>
                        <Typography paragraph={true}>

                        See your content come to life in the game as we incorporate your designs.
                        </Typography>
                </Item>
            </Item>

            <Item title={"Use of Funds"} expanded={expanded} handleChange={handleChange}  component={"div"}>

                <Typography paragraph={true}>

                    This project and the core team has been completely bootstrapped. We have not taken a dime of outside
                    money or VC and have no source of external funding. The benefit is that we are not beholden to
                    shareholders or providing quick returns.
                </Typography>

                <Typography paragraph={true}>

                    We have deigned the mint of the Blood Hammer Daojo to enable us to raise enough funds to cover
                    operations for approximately 18 months. This will allow us to expand the team and provide the time
                    we
                    need to launch the existing game, stand up the remaining Daojos, and finally automate their
                    integration
                    with the game’s NFT item system.
                </Typography>

                <Typography paragraph={true}>

                    While we fully expect there to be a vibrant secondary market for memberships (and items!), we do not
                    think the long-term value is in a quick 3x or even 10x return. This is about standing up an entire
                    economic system with multiple layers of economic incentives that dovetail into, and reenforce, each
                    other.
                </Typography>

                <Typography paragraph={true} component={"div"}>

                    The proceeds from <b>any sale</b> (including the proceeds from this or any future Membership Card
                    sales), net of taxes, will be used to fund EvilPlan Inc. operations, including, but not limited
                    to...

                    <BulletList>
                        <li>
                            Operations including, but not limited to, employee salaries, contractors, 3rd party vendors,
                            and facilities costs
                        </li>
                        <li>
                            Product development & engineering
                        </li>
                        <li>
                            Game development including any new, or existing, original IP
                        </li>
                        <li>
                            Deployment, hosting, and network infrastructure
                        </li>
                        <li>
                            Daojo infrastructure setup, operational costs, and fees
                        </li>
                        <li>
                            Legal, compliance and accounting
                        </li>
                        <li>
                            Marketing, merchandising, partnerships and sales
                        </li>
                        <li>
                            Community development
                        </li>
                        <li>
                            Future internal or community initiatives
                        </li>
                    </BulletList>
                    </Typography>

            </Item>

            <Item title={"About EvilPlan"} expanded={expanded} handleChange={handleChange}>
                    EvilPlan’s core team is comprised of industry professionals from gaming, software, and sports
                    entertainment with an average of +10 years professional experience. The founding members have worked
                    in executive or senior leadership roles at their own startups, FAANG, and other high-value public
                    companies.
            </Item>

            <Item title={"What are the Terms of Sale?"}>
                You can view the full Terms of Sale <Link to={"/legal"}>here</Link>.
            </Item>
        </Container>
    </Grid>
}
export default ManualPage;